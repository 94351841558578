import axios from "axios";
import Cookies from "js-cookie";
const url = "https://developerteam.in/nsdmismaster-api/public/api/";
// const url = "https://api.nsdmismaster.in/public/api/";
// const url = "http://127.0.0.1:8000/api/";
const base_url = url;

const Api = async (endpoint, method, body) => {
  const aTokenString = Cookies.get("AdminToken")
    ? Cookies.get("AdminToken")
    : "";
  const token = aTokenString ? JSON.parse(aTokenString) : "";
  const options = {
    url: base_url + endpoint,
    method: method || "GET",
    data: body,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    responseType: "json",
  };

  if (method === "GET") {
    options.data = undefined;
  }
  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(`Error from ${endpoint} >> ${error}`);
      throw error.response;
    });
};

export default Api;
